import React from 'react'
import {graphql, Link} from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Wrapper from '../components/wrapper'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {getBlogUrl} from '../lib/helpers'

export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      keywords
      innerImage {
      asset {
        fluid(maxHeight: 300) {
          ...GatsbySanityImageFluid_withWebp
        }
       }
      }
    }
    category: sanityCategory(id: {eq: $id}) {
      title
      description
      posts {
        mainImage {
      ...SanityImage
      alt
      asset{
        fluid {
          ...GatsbySanityImageFluid_withWebp_noBase64
           }
         }
       }
        _id
        title
        publishedAt
        slug {
          current
        }
      }
    }
  }

`
const CategoryPostTemplate = props => {
  const {data = {}, errors} = props
  const {title, description, posts} = data.category || {}
  const site = (data || {}).site
  return (
    <Layout>
    <SEO title={title} description={description} />
    <BackgroundImage
    style={{
      backgroundPosition: 'top',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      textShadow: '1px 2px 6px #000000',
      textAlign: 'center',
      height: 100
    }}
    fluid={site.innerImage.asset.fluid}
    backgroundColor={`#102514`}
  >
    <h2>Blog Categories</h2>
  </BackgroundImage>
      <Wrapper>
      {errors && <GraphQLErrorList errors={errors} />}
      {!data.category && <p>No category data</p>}
      <SEO  />
      <article>
        <h1>Category: {title}</h1>
        <p>{description}</p>
        {/*
          If there are any posts, add the heading,
          with the list of links to the posts
        */}
        {posts && (
          <React.Fragment>
            <h2>Posts</h2>
            <ul>
              { posts.map(post => (
                <li key={post._id}>
                  <Link to={getBlogUrl(post.publishedAt, post.slug)}>{post.title}</Link>
                </li>))
              }
            </ul>
          </React.Fragment>)
        }
      </article>
      </Wrapper>
    </Layout>
  )
}

export default CategoryPostTemplate